import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fareRuleTypesData, mapFareType } from '../../data/markup/fareRuleType';
import FetchUsersInput from '../../components/form/FetchUsersInput';
import { addMarkup } from '../../controllers/settings/addMarkups';
import AirlinesInput from '../../components/form/AirlinesInput';
import { addMarkupUserType } from '../../controllers/settings/addMarkupUserType';
import { markupReqTemp } from '../../data/markup/markupReqData';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { useDispatch } from 'react-redux';
import { updateFareRule } from '../../controllers/settings/updateFareRule';

export default function AddMarkup({returnData,reload,updateObj,setUpdateObj}) {
  const [userType,setUserType] = useState("");
  const [markup,setMarkup] = useState("");
  const [markupType,setMarkupType] = useState("");
  const [markupValue,setMarkupValue] = useState(0);
  const [provider,setProvider] = useState("");
  const [user,setUser] = useState();
  const [description,setDescription] = useState('');
  const [reqField,setReqField] = useState();
  const dispatch = useDispatch();

  console.log(updateObj)

  useEffect(() => {
    setUserType(updateObj?.for || "")
    setMarkup(updateObj?.fareRuleType || "")
    setMarkupType(updateObj?.markType||"")
    setMarkupValue(updateObj?.markUp||"")
    setProvider(updateObj?.flightProvider||"")
    setUser(updateObj?.for)
    setDescription(updateObj?.ruleName||"")
    setReqField(updateObj?.airLines?.at(0))

  },[updateObj])

  const initState = () => {
    setUserType("")
    setMarkup("")
    setMarkupType("")
    setMarkupValue(0)
    setProvider("")
    setUser()
    setDescription("")
    setReqField()
  }

  async function handleSubmit(ev) {
    ev.preventDefault();

    if(!markupValue)
      return dispatch(setAlertMsg(['error','Markup value cannot be empty!']))
    
    let res = null;
    let req = markupReqTemp;
    req.fareRuleType = markup;
    req.markType = markupType;
    req.markUp = parseInt(markupValue);
    req.ruleName = "";
    req.ruleName = description;
    req.for = userType.toUpperCase();
    req['flightProvider'] = provider;
    if(markup === 'AIRLINE')
      req['airLines'] = [reqField];
    else req[markup] = reqField;

    // console.log('reqData',req)
    if(updateObj) {
      let updatedObj = {
        ruleName: description||"",
        markType: markupType||"",
        markUp: Number(markupValue)||"",
        flightProvider: provider||"",
      }
      res = await updateFareRule(updateObj?.id,updatedObj)
      setUpdateObj();
    }
    else if(user && user.id)
      res = await addMarkup(user.id,req)
    else res = await addMarkupUserType(userType.toUpperCase(),req)

    console.log('res',res)
    if(res.return) {
      reload && reload();
      dispatch(setAlertMsg(['success',updateObj ? 'Markup Updated' : 'Markup Added']));
      initState();
    }
    else dispatch(setAlertMsg(['error',updateObj ? 'Failed updating markup' : 'Failed Adding Markup']))
    
  }

  function handleUserTypeChange(val) {
    setUserType(val);
    if(returnData)
      returnData(val.toUpperCase());
  }

  return (
    <form onSubmit={handleSubmit} className='my-5 p-6 bg-secondary rounded-lg'>
      <div className='flex flex-col gap-8'>
        <div className='flex gap-5 flex-wrap'>
          {!updateObj? 
            <TextField select value={markup} required onChange={(ev) => setMarkup(ev.target.value)} label='Markup Rule' size='small' className='min-w-[100px]'>
              {fareRuleTypesData.map((val,i) => (
                <MenuItem key={i} value={val} className='capitalize'>{mapFareType(val.toLowerCase())}</MenuItem>
              ))}
            </TextField>
          :null}
          <TextField select value={provider} onChange={(ev) => setProvider(ev.target.value)} label='Flight Provider' size='small' className='min-w-[150px]'>
            <MenuItem value='AMADEUS'>Amadeus</MenuItem>
            <MenuItem value='AMADEUS_DXB'>Amadeus DXB</MenuItem>
            <MenuItem value='TRAVEL_PORT'>Travelport</MenuItem>
            <MenuItem value='ALL'>ALL</MenuItem>
          </TextField>
          <TextField select required value={markupType} onChange={(ev) => setMarkupType(ev.target.value)} label='Markup Type' size='small' className='min-w-[150px]'>
            <MenuItem value='PERCENTAGE'>Percentage</MenuItem>
            <MenuItem value="VALUE">Value</MenuItem>
          </TextField>
          {!updateObj?
            <TextField select value={userType} onChange={(ev) => handleUserTypeChange(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
              <MenuItem value="Customer">Customer</MenuItem>
              <MenuItem value="Sub_Agent">Agent</MenuItem>
              <MenuItem value="Corporate">Company</MenuItem>
            </TextField>
          :null}
          {!updateObj?
            <FetchUsersInput label={userType} value={user} onChange={(val) => setUser(val)} />
          :null}
        </div>
        <hr />
        <div className='flex gap-5 flex-wrap items-center'>
          {markup === 'AIRLINE' && !updateObj ? (
            <AirlinesInput val={reqField} returnData={(val) => setReqField(val ? val.id : null)} label={markup} />
          ): !updateObj ?(
            <TextField value={reqField || ''} onChange={(ev) => setReqField(ev.target.value)} size='small' label={markup} />
          ):null}
          <TextField size='small' required value={markupValue} onChange={(ev) => setMarkupValue(ev.target.value)} label={"Markup Value"} />
          <TextField size='small' required value={description} onChange={(ev) => setDescription(ev.target.value)} label={"Description"} />
          <button className='btn_outlined'>{updateObj ? 'Update Data' : 'Save Data'}</button>
        </div>
      </div>

    </form>

  )
}
